<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6">
          <b-form-group
            class="md-2"
            label="Select Languages"
            label-for="mc-select-language"
          >
            <v-select
              v-model="selectedLanguages"
              class="language"
              placeholder="Select Languages"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :disabled="true"
              @input="getDescbyLang($event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Amenity Name"
            label-for="hc-amenity-name"
          >
            <b-form-input
              v-if="amenitiesEditForm.amenities[0] && amenitiesEditForm.amenities"
              v-model="amenitiesEditForm.amenities[0].amenity_key"
              class="amenity_name"
              placeholder="Amenity Name"
              :clearable="false"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="$emit('on-Submit', amenitiesEditForm)">
          <b-row>
            <b-col v-for="(item, index) in amenitiesEditForm.amenities" :key="index" md="6">
              <b-form-group
                :label="`${amenitiesEditForm.amenities[index].language_name}`"
                label-for="hc-amenity-name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="`${amenitiesEditForm.amenities[index].language_name}`"
                  rules="required|max:20"
                  autocomplete="off"
                >
                  <b-form-input
                    v-model="amenitiesEditForm.amenities[index].amenity"
                    :placeholder="`${amenitiesEditForm.amenities[index].language_name}`"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col md="12" class="demo-inline-spacing">
              <b-button
                :disabled="loading"
                variant="primary"
                type="submit"
                @click="updateAmenities"
              >
                Save
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
              <b-button
                variant="secondary"
                :to="{name: ((checkLoginRole() === RoleEnum.SuperAdmin) || checkAccess.view)?'amenities-list':'dashboard'}"
              >
                Cancel
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard
} from 'bootstrap-vue'
import {
  LanguageEnum, resConditionCheck, RoleEnum,
} from '@/helpers/constant'
import vSelect from 'vue-select'
import errorResponseHandler from '@/services/errorHandler'
import checkLoginRole from '@/helpers/checkLoginRole'
import accessRightCheck from '@/helpers/accessRightCheck'
import languageService from '@/services/language/language.service'
import AmenitiesService from '@/services/amenities/amenities.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@core/utils/validations/validations'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      amenitiesEditForm: {
        group_id: this.$route.params.id ? this.$route.params.id : '',
        amenities: []
      },
      amenity_name: "",
      lang_name: "",
      amenitiesData: {},
      selectedLanguage: LanguageEnum.English || '',
      selectedLanguages: [],
      checkAccess: {},
      loading: false,
      checkLoginRole,
      groupId: this.$route.params.id ? this.$route.params.id : '',
      titleMsg: '',
       snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },
      RoleEnum,
      staticObject: {
        language_id: '',
        amenity: '',
      },
      required,
    }
  },
  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    if (this.$route.params.id) {
      await this.getAmenityDetail()
    }
  },
  methods: {
    async getAmenityDetail() {
      await this.getLanguageDropdown();

      const payload = {
        group_id: this.groupId
      }

      await AmenitiesService.getAmenityById(payload).then(res => {
        if (resConditionCheck(res.status) && res.data) {
          this.amenitiesData = res.data.amenities

          for (let i = 0; i < this.selectedLanguages.length; i += 1) {
            this.amenitiesEditForm.amenities.push({
              ...this.staticObject,
              language_id: this.selectedLanguages[i].value,
              language_name: this.selectedLanguages[i].label,
              amenity: this.amenitiesData[i] && this.amenitiesData[i].name ? this.amenitiesData[i].name : '',
              amenity_key: /*this.amenitiesData[i] && this.amenitiesData[i].key ?*/ this.amenitiesData[0].key
            })
            this.amenitiesEditForm.amenities[i].amenity = this.amenitiesData[i] && this.amenitiesData[i].name ? this.amenitiesData[i].name : ''
          }
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },
    async updateAmenities() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          AmenitiesService.updateAmenities(this.amenitiesEditForm).then(res => {
            if (resConditionCheck(res.status) && res.data.message) {
              this.loading = false
              this.$router.push('/amenities/list')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            const errorData = errorResponseHandler(error)
            this.loading = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      });
    },
    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown()
        if (resConditionCheck(res.status) && res.data.data) {
          this.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            value: e.id
          }))
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
