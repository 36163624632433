import axios from 'axios'

function getAll() {
  return axios.get('hotel/hotel-amenities/1')
}

function getAmenityById(payload) {
  return axios.get(`hotel/view-hotel-amenities/${payload.group_id}`)
}

function updateAmenities(data) {
  return axios.put('hotel/hotel-amenities', data)
}

export default {
  getAll,
  getAmenityById,
  updateAmenities
}
